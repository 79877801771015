import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "@reach/router"

import Layout from "../components/layout"
import Hero from "../components/hero"
import About from "../components/about"
import Featured from "../components/featured"
import FeaturedDetails from "../components/featured-details"
import TeamList from "../components/team-list"
import CallToAction from "../components/call-to-action"
import SEO from "../components/seo"

// import LevelUp from "../components/level-up"
// import BegginingIcon from "../images/timeline/beggining.svg"
// import FoundingDayIcon from "../images/timeline/founding-day.svg"
// import TeamGrowsIcon from "../images/timeline/team-grows.svg"
// import TopStartupsIcon from "../images/timeline/top-startups.svg"
// import TopChartsIcon from "../images/timeline/top-charts.svg"
// import RevenueIcon from "../images/timeline/revenue.svg"
// import NewPartnershipIcon from "../images/timeline/new-partnership.svg"

const StoryPage = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            teamMembers {
              name
              title
              imageName
              socials {
                email
                linkedIn
                facebook
                behance
                artStation
              }
              founder
            }
            # timeline {
            #   title
            #   description
            #   color
            #   date
            #   icon
            # }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Hero
        imagePath="hero/about.jpg"
        title="About"
        coloredTitle="Us"
        description="Know our story"
      />

      <About imagePath="about/background.png" />

      <Featured
        imagePath="featured/prom_agentvolt_color.png"
        logoPath="games/agent-volt/logo.png"
      >
        <FeaturedDetails
          title="Shoot down other enemies in this intense FPS!"
          description="Check it out on the iOS App Store!"
          callToAction={
            <CallToAction
              text="Play now!"
              href="https://apps.apple.com/us/app/agent-bullet-3d/id1498230736"
              blank
            />
          }
        />
      </Featured>

      <TeamList
        title="Meet the founders"
        coloredWords={2}
        description="The foundation behind Volt Games"
        teamMembers={
          site.siteMetadata.teamMembers.filter(member => member.founder)
          // .map(member => ({
          //   ...member,
          //   title: member.title + " & CO-FOUNDER",
          // }))
        }
      />

      {/* <LevelUp
        events={[
          {
            title: "THE BEGINNING",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#99CC33",
            date: "Oct 2019",
            icon: <BegginingIcon />,
          },
          {
            title: "THE FOUNDING DAY",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#5FB0E5",
            date: "Nov 2019",
            icon: <FoundingDayIcon />,
          },
          {
            title: "THE TEAM GROWS",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#7D34BC",
            date: "Dez 2019",
            icon: <TeamGrowsIcon />,
          },
          {
            title: "PT TOP STARTUPS",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#C53C52",
            date: "Jan 2020",
            icon: <TopStartupsIcon />,
          },
          {
            title: "TOP CHARTS",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#FB5937",
            date: "Apr 2020",
            icon: <TopChartsIcon />,
          },
          {
            title: "REVENUE",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#FF7900",
            date: "Oct 2020",
            icon: <RevenueIcon />,
          },
          {
            title: "NEW PARTNERSHIP",
            description:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.",
            color: "#FFBB06",
            date: "Dez 2020",
            icon: <NewPartnershipIcon />,
          },
        ]}
      /> */}

      <Featured imagePath="featured/story.jpg" large>
        <FeaturedDetails
          imagePath="featured/sherlock-home.png"
          title="Have a game you want Volt Games to publish?"
          description="Interested in getting in touch with us?"
          callToAction={
            <CallToAction
              text="Contact us"
              background={"#FFFFFF"}
              color={"#5B5B5B"}
              href="/contact/"
              cursor
            />
          }
        />
      </Featured>

      <SEO title="Story" />
    </Layout>
  )
}

export default StoryPage
